import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17')
];

export const server_loads = [0];

export const dictionary = {
		"/(marketing)": [3,[2]],
		"/(marketing)/agb": [9,[2]],
		"/avatar": [~17],
		"/chat": [~16],
		"/(marketing)/impressum": [10,[2]],
		"/(marketing)/rechtsgebiete": [11,[2]],
		"/(marketing)/rechtsgebiete/erbrecht": [13,[2]],
		"/(marketing)/rechtsgebiete/sozialrecht": [12,[2]],
		"/(marketing)/rechtsgebiete/verkehrsrecht": [14,[2]],
		"/(marketing)/rechtsschutz": [5,[2]],
		"/(marketing)/rechtsschutz/internetschutz": [8,[2]],
		"/(marketing)/rechtsschutz/rundumschutz": [7,[2]],
		"/(marketing)/rechtsschutz/verkehrsrechtsschutz": [6,[2]],
		"/(marketing)/so-fragst-du": [4,[2]],
		"/(marketing)/ueber-uns": [15,[2]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.js';